<template>
  <div class="questions">
    <div class="questions-container">
      <div class="questions-container-top">
        <div class="exam-name">{{ examInfo.examName }}</div>
        <div class="exam-time">考试时间：{{ examInfo.examStartTime | dateFormat }} 至 {{ examInfo.examEndTime | dateFormat }} </div>
      </div>

      <!--  考试题  -->
      <div class="question-content">
        <!--        <div style="width: 100%; display: flex; justify-content: center;margin-bottom: 40px">-->
        <!--          <el-button type="success" style="width: 8vw; height: 2.4vw" @click="downloadPaper">-->
        <!--            下载试卷-->
        <!--          </el-button>-->
        <!--        </div>-->

        <el-table
          style="width: 100%; font-size: 17px"
          :data="questions"
          :header-cell-style="{color: '#3979F3'}"
        >
          <el-table-column prop="questionId" label="题号" align="center" />

          <el-table-column label="最后提交时间" align="center">
            <template v-slot="{row}">
              {{ row.updateTime ? new Date(row.updateTime).toLocaleTimeString(): '' }}
            </template>
          </el-table-column>

          <el-table-column prop="fileSize" label="代码长度" align="center" />

          <el-table-column label="操作" width="220px" align="center">
            <template v-slot="{row, $index}">
              <el-button type="primary" @click="handleView(row, $index)">源代码提交</el-button>
            </template>
          </el-table-column>

        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import request from '../../utils/request'
import { downloadPaper, getExamInfo, getQuestionList } from '@/api'

export default {
  name: 'Index',
  data() {
    return {
      btnDisabled: false,
      countdown: 0,
      questions: [
        {
          'classroomExamId': null,
          'examineeId': null,
          'questionId': null,
          'code': null,
          'fileSize': null,
          'createTime': null,
          'updateTime': null
        }
      ],
      examInfo: {}
    }
  },

  async mounted() {
    getExamInfo()
      .then(({ data }) => {
        this.examInfo = data
        this.questions.splice(0, 1)

        // 获取题目状态
        getQuestionList({ classroomExamId: data.id }).then(res => {
          this.questions = res.data
        })
      })
  },

  methods: {
    handleView(row) {
      // this.$router.push(`/tests/edit/${this.examInfo.id}/${row.questionId}`)
      this.$router.push({ path: '/tests/edit', query: { examId: this.examInfo.id, questionId: `${row.questionId}` }})
    },

    downloadPaper() {
      downloadPaper().then(res => {
        if (!res.data) {
          this.$message.warning('试卷不存在，请联系监考教师')
          return
        }
        window.open(res.data)
      })
    }
  }
}
</script>

<style scoped>
.questions-container {
  width: 1200px;
  border-radius: 8px;
  margin: 50px auto;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}

.questions-container-top {
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exam-name {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

.exam-time {
  font-size: 18px;
}

.question-content {
  padding: 40px 100px 80px 100px;
}
</style>
